import googleplaylogo from '../../images/google-play.png';
import appstorelogo from '../../images/app-store.png';
import { LightUpButton } from '../../components/TrailingIconButton/TrailingIconButton';
import Mockup from '../../images/Mockup.png';
import './Home.css';

const Home = () => {
    return (
        <div className="homepage">
            <div>
                <img src={Mockup} className="mockup" />
            </div>
            <div className="store-info">
                <span className="app-type">For Buyers</span>
                <div className="storebuttonbox">
                    <LightUpButton className="appstorebuttons" textclass="storebuttontext" id="appstorebutton" image={appstorelogo} image_id="app-store-icon">
                        App Store
                        </LightUpButton>
                    <LightUpButton className="playstorebuttons" textclass="storebuttontext" id="playstorebutton" image={googleplaylogo} image_id="play-store-icon">
                        Play Store
                        </LightUpButton>
                </div>
                <br /><br />
                <span className="app-type">For Sellers</span>
                <div className="storebuttonbox">
                    <LightUpButton className="appstorebuttons" textclass="storebuttontext" id="appstorebutton" image={appstorelogo} image_id="app-store-icon">
                        App Store
                        </LightUpButton>
                    <LightUpButton className="playstorebuttons" textclass="storebuttontext" id="playstorebutton" image={googleplaylogo} image_id="play-store-icon">
                        Play Store
                        </LightUpButton>
                </div>
            </div>

            <p id="established" > est. 2020</p>
        </div>
    );
}

export default Home;