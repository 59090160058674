import React from 'react';
import './DocumentSection.css'; 


const DocumentSection = (props: {title: string}) => {
    return (
        <div>
            <hr className="divider" />
            <span className="section-title">
                {props.title}
            </span>
            <hr className="divider" />
        </div>
    )
}

export default DocumentSection;