import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import logo from '../../images/logo.jpeg';
import './UpdatePassword.css';

export default class UpdatePassword extends React.Component<{}>{
    private UpdateSchema: any;
    constructor(props: any) {
        super(props);
        this.UpdateSchema = Yup.object().shape({
            "New Password": Yup.string()
                .min(8)
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/,
                    "Must include at least 1 uppercase and 1 lowercase letter"
                )
                .required('Please enter your new password'),
            "Confirm New Password": Yup.string()
                .oneOf([Yup.ref('New Password'), null], "Passwords must match")
                .required('Please confirm your new password'),
        });
    }

    async updatePassword(newPassword: string, confirmPassword: string) {
        let queryParams = new URLSearchParams(window.location.search);
        let randomToken = queryParams.get('token');
        let email = queryParams.get('email');
        if (newPassword === confirmPassword) {
            await fetch('/user-update-password', {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    newPassword: newPassword,
                    token: randomToken,
                    userEmail: email
                })
            });
        }
        else {
            // alert
        }
    }

    render() {
        return (
            <div>
                <img className="update-logo" src={logo} />
                <Formik
                    initialValues={{ "New Password": "", "Confirm New Password": "" }}
                    validationSchema={this.UpdateSchema}
                    onSubmit={async values => {
                        await this.updatePassword(values["New Password"], values["Confirm New Password"])
                    }}
                >{({ errors, touched }) => (
                    <Form >
                        <div className="group">
                            <label className="label">New Password</label><br />
                            <Field id="password" type="password" name="New Password" className="input" data-type="password" />
                            {(errors["New Password"] && touched["New Password"]) ? (
                                <div className="ErrorMsg"><ErrorMessage name="New Password" /></div>
                            ) : null}
                        </div>
                        <div className="group">
                            <label className="label">Confirm New Password</label><br />
                            <Field id="password-confirm" type="password" name="Confirm New Password" className="input" data-type="password" />
                            {(errors["Confirm New Password"] && touched["Confirm New Password"]) ? (
                                <div className="ErrorMsg"><ErrorMessage name="Confirm New Password" /></div>
                            ) : null}
                        </div>
                        <div className="group">
                            <Field type="submit" className="button" value="Update Password" />
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        );
    }

}