import React from 'react';
import DocumentSection from '../../components/DocumentSection/DocumentSection';

const TermsNConditions = () => {
    return(
        <div>
            <DocumentSection
            title="Terms & Conditions" 
            />
            <div>
                <p>

                </p>
            </div>
            <div>
                <p>

                </p>
            </div>
        </div>
    )
}

export default TermsNConditions;