import React from 'react';
import DocumentSection from '../../components/DocumentSection/DocumentSection';

const PrivacyPolicy = () => {
    return (
        <div>
            <DocumentSection
            title="Privacy Policy" 
            />
            <div>
                <p>

                </p>
            </div>
            <div>
                <p>

                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;