import React from 'react';
import './ErrorView.css';
import ErrorImage from '../../images/errorviewimage.png'


export default class ErrorView extends React.Component<{}>{
    render() {
        return (
            <div className="errorContainer">
                <img id='error-image' src={ErrorImage}></img>
                <p className="errorText"> 🙁 An error occured 🙁</p>
            </div>
        );
    }
}