import styles from './header.module.css';
import Logo from '../../images/logo.jpeg';

export const Header = () => {
    return (
        <>
            <div className={styles.container}>
                <img src={Logo} className={styles.logo} />
                <span className={styles.name}>UNQSNKS</span><br /><br />
            </div>
            <br />
            <br />
        </>
    );
}