import React from 'react';
import Home from './views/Home/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import VerificationSuccess from './views/Verification-Success/Verification-Success';
import ErrorView from './views/ErrorView/ErrorView';
import UpdatePassword from './views/UpdatePassword/UpdatePassword';
import UpdateSuccess from './views/UpdateSuccess/UpdateSuccess';
import TermsNConditions from './views/Terms&Conditions/TermsNConditions';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import { Header } from './components/Header/header';

function App(): JSX.Element {
  return (
    <div className="App">
      <Header/>
      <Router>
        <Switch>
          <Route path='/Error'>
            <ErrorView />
          </Route>
          <Route path='/error'>
            <ErrorView />
          </Route>
          <Route path='/VerificationSuccess'>
            <VerificationSuccess />
          </Route>
          <Route path='/verificationsuccess'>
            <VerificationSuccess />
          </Route>
          <Route path='/UpdatePassword'>
            <UpdatePassword />
          </Route>
          <Route path='/updatepassword'>
            <UpdatePassword />
          </Route>
          <Route path='/UpdateSuccess'>
            <UpdateSuccess />
          </Route>
          <Route path='/updatesuccess'>
            <UpdateSuccess />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/terms-and-conditions'>
            <TermsNConditions />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
