import React from 'react';
import logo from '../../images/logo.jpeg';

export default class UpdateSuccess extends React.Component<{}>{
    render() {
        return (
            <div id="information">
                <img id="logo" src={logo}></img>
                <h1>Update was successful!</h1>
                <h4>You can now return to the UNQSNKS app and log in</h4>
            </div>
        );
    }

}