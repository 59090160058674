import React, { Component } from 'react'
import './TrailingIconButton.css';
interface LightUpButtonProps {
    //CSS class of the button
    className: string,
    //CSS id of the button
    id?: string,
    //path of the image of the button
    image?: string,
    //CSS id for the image
    image_id?: string,
    //CSS class for text
    textclass?: string
    children?: JSX.Element | string,
}

//LightUpButton 
export class LightUpButton extends Component<LightUpButtonProps, {}> {
    constructor(props: LightUpButtonProps) {
        super(props)
    }

    render(): JSX.Element {
        return (
            <button className={this.props.className} id={this.props.id}>
                <span className="button-contents">
                <span className={this.props.textclass}>
                    {this.props.children}
                </span>
                <img src={this.props.image} id={this.props.image_id}></img>
                </span>
            </button>
        );
    }
}